import { light, Theme } from '@sumup/design-tokens';

export const type = 'light';

const white = 'rgba(255, 0, 255, 0.1)';
const black = 'rgba(255, 0, 255, 1)';

const neutrals = {
  n100: 'rgba(255, 0, 255, 0.2)',
  n200: 'rgba(255, 0, 255, 0.3)',
  n300: 'rgba(255, 0, 255, 0.4)',
  n500: 'rgba(255, 0, 255, 0.5)',
  n700: 'rgba(255, 0, 255, 0.6)',
  n800: 'rgba(255, 0, 255, 0.7)',
  n900: 'rgba(255, 0, 255, 0.8)',
};

const blues = {
  b100: 'rgba(255, 0, 255, 0.2)',
  b200: 'rgba(255, 0, 255, 0.3)',
  b300: 'rgba(255, 0, 255, 0.4)',
  b400: 'rgba(255, 0, 255, 0.5)',
  b500: 'rgba(255, 0, 255, 0.6)',
  b700: 'rgba(255, 0, 255, 0.7)',
  b900: 'rgba(255, 0, 255, 0.8)',
};

const greens = {
  g100: 'rgba(255, 0, 255, 0.2)',
  g200: 'rgba(255, 0, 255, 0.3)',
  g300: 'rgba(255, 0, 255, 0.4)',
  g500: 'rgba(255, 0, 255, 0.6)',
  g700: 'rgba(255, 0, 255, 0.7)',
  g900: 'rgba(255, 0, 255, 0.8)',
};

const violets = {
  v100: 'rgba(255, 0, 255, 0.2)',
  v200: 'rgba(255, 0, 255, 0.3)',
  v300: 'rgba(255, 0, 255, 0.4)',
  v500: 'rgba(255, 0, 255, 0.6)',
  v700: 'rgba(255, 0, 255, 0.7)',
  v900: 'rgba(255, 0, 255, 0.8)',
};

const oranges = {
  o100: 'rgba(255, 0, 255, 0.2)',
  o200: 'rgba(255, 0, 255, 0.3)',
  o300: 'rgba(255, 0, 255, 0.4)',
  o500: 'rgba(255, 0, 255, 0.6)',
  o700: 'rgba(255, 0, 255, 0.7)',
  o900: 'rgba(255, 0, 255, 0.8)',
};

const yellows = {
  y100: 'rgba(255, 0, 255, 0.2)',
  y200: 'rgba(255, 0, 255, 0.3)',
  y300: 'rgba(255, 0, 255, 0.4)',
  y500: 'rgba(255, 0, 255, 0.6)',
  y700: 'rgba(255, 0, 255, 0.7)',
  y900: 'rgba(255, 0, 255, 0.8)',
};

const reds = {
  r100: 'rgba(255, 0, 255, 0.2)',
  r200: 'rgba(255, 0, 255, 0.3)',
  r300: 'rgba(255, 0, 255, 0.4)',
  r500: 'rgba(255, 0, 255, 0.6)',
  r700: 'rgba(255, 0, 255, 0.7)',
  r900: 'rgba(255, 0, 255, 0.8)',
};

const primary = {
  p100: blues.b100,
  p200: blues.b200,
  p300: blues.b300,
  p400: blues.b400,
  p500: blues.b500,
  p700: blues.b700,
  p900: blues.b900,
};

const misc = {
  shadow: 'rgba(255, 0, 255, 0.07)',
  overlay: 'rgba(255, 0, 255, 0.4)',
  bodyBg: white,
  bodyColor: neutrals.n900,
  info: 'rgba(255, 0, 255, 1)',
  confirm: 'rgba(255, 0, 255, 1)',
  alert: 'rgba(255, 0, 255, 1)',
  notify: 'rgba(255, 0, 255, 1)',
};

const colors = {
  white,
  black,
  ...neutrals,
  ...blues,
  ...greens,
  ...yellows,
  ...reds,
  ...oranges,
  ...violets,
  ...primary,
  ...misc,
};

export const debugTheme: Theme = process.env.NEXT_PUBLIC_DEBUG_THEME
  ? { ...light, colors }
  : { ...light };
