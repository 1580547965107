import {
  ContentfulProps,
  ContentTypes,
  ContentfulApiSelectors,
} from 'types/constants';
import env from 'services/env';

export const CONTENTFUL_CREDENTIALS: ContentfulProps = {
  SPACE: env('CONTENTFUL_SPACE_ID'),
  ENV: env('CONTENTFUL_ENV'),
  TOKEN_DELIVERY: env('CONTENTFUL_TOKEN_DELIVERY'),
  TOKEN_PREVIEW: env('CONTENTFUL_TOKEN_PREVIEW'),
  PREVIEW_HOST: env('CONTENTFUL_PREVIEW_HOST'),
  ENV_POS: 'pos-tiller',
};

export const CONTENT_TYPES: ContentTypes = {
  ARTICLE: 'article',
  SECTION: 'section',
  COUNTRY: 'country',
};

export const CONTENTFUL_API_SELECTORS: ContentfulApiSelectors = {
  ARTICLE_DATA: {
    LANDING_PAGE:
      'sys.id,fields.title,fields.sections,fields.name,fields.slug,metadata.tags',
    SECTION_PAGE:
      'sys.id,fields.title,fields.sections,fields.name,fields.articleDescription,fields.slug,fields.metaTitle,metadata.tags,fields.predictedReasons,fields.IDSDetailedReason',
  },
  SECTION_DATA: {
    SECTION_PAGE:
      'sys.id,fields.sectionTitle,fields.slug,fields.metaDescription',
    ID_ONLY: 'sys.id',
  },
};

export const CONTENTFUL_COUNTRY_DATA = {
  COUNTRY_DATA:
    'sys.id,fields.name,fields.twoLetterIsoCode,fields.supportedLanguages,fields.flagName,fields.defaultZendeskSupportCentre,fields.escalationOptions,fields.emailAddress,metadata.tags',
};

export const DEFAULT_FOOTER_ENTRY_ID = '5luEVxjsNGIA88yEuoyymE';

export const CONTENTFUL_ENTRIES_ROOT_URL = `https://app.contentful.com/spaces/${CONTENTFUL_CREDENTIALS.SPACE}/environments/${CONTENTFUL_CREDENTIALS.ENV}/entries/`;

export const ZENDESK = {
  CONTENT_TYPES: {
    articles: 'article',
    sections: 'section',
  },
  QUERY_FIELDS: {
    articles: 'fields.zendeskId',
    sections: 'fields.zendesk_id',
  },
  CONTENTFUL_SELECTORS: 'sys.id,fields.slug',
};

export const EXISTS_FIELDS = {
  ARTICLE: {
    'fields.title[exists]': true,
    'fields.slug[exists]': true,
    'fields.articleDescription[exists]': true,
    'fields.scArticleBody[exists]': true,
  },
  SECTION: {
    'fields.sectionTitle[exists]': true,
    'fields.slug[exists]': true,
    'fields.metaDescription[exists]': true,
  },
  POS: {
    ARTICLE: {
      'fields.title[exists]': true,
      'fields.scArticleBody[exists]': true,
    },
    SECTION: {
      'fields.sectionTitle[exists]': true,
    },
  },
};
