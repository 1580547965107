import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { Button, Headline, useModal } from '@sumup/circuit-ui';
import { spacing } from '@sumup/circuit-ui/legacy';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Entry } from 'contentful';
import { Text } from '@contentful/rich-text-types';

import {
  findUserValidLocale,
  getCountryFromLocale,
} from 'services/localization';
import { StyledComponentsParams } from 'types/components';
import { GEOLOCATION_COOKIES } from 'constants/cookies';
import { getCurrentPageName } from 'services/pages/common';
import { findContentfulEntry } from 'services/contentful';
import { CONTENT_TYPES } from 'constants/contentful';
import { createPathFromArray } from 'services/common';
import { ContentfulArticle, ContentfulModalAllLocales } from 'types/common';

const ModalWrapper = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    align-items: center;
    padding: ${theme.spacings.giga} ${theme.spacings.tera};
  `,
);

const StyledButton = styled(Button)(
  ({ theme }: StyledComponentsParams) => css`
    margin-top: ${theme.spacings.kilo};
    border: none;
  `,
);

const GeolocationModal = ({
  articleId,
  sectionId,
}: {
  articleId: string;
  sectionId: string;
}): JSX.Element => {
  const [targetRoute, setTargetRoute] = useState('');
  const [modalData, setModalData] = useState<ContentfulModalAllLocales>(null);
  const router = useRouter();
  const { setModal, removeModal } = useModal();
  const userCountry = Cookie.get(GEOLOCATION_COOKIES.ORIGIN_COUNTRY);
  const hideGeoLocationModal = Cookie.get(
    GEOLOCATION_COOKIES.HIDE_GEOLOCATION_MODAL,
  );

  const userLocale = useMemo(
    () =>
      findUserValidLocale({
        userCountry,
        userLanguage:
          global.navigator &&
          (global.navigator.userLanguage || global.navigator.language)?.split(
            '-',
          )[0],
        appLocales: router.locales,
      }),
    [router.locales, userCountry],
  );

  const onClose = useCallback(() => {
    if (!Cookie.get(GEOLOCATION_COOKIES.HIDE_GEOLOCATION_MODAL)) {
      Cookie.set(GEOLOCATION_COOKIES.HIDE_GEOLOCATION_MODAL, 'true', {
        expires: 31,
      });
      removeModal();
    }
  }, [removeModal]);

  useEffect(() => {
    if (
      userCountry &&
      userCountry !== getCountryFromLocale(router.locale) &&
      !hideGeoLocationModal
    ) {
      findContentfulEntry({
        contentType: 'modal',
        contentfulId: 'D9mCfQlqvNFB1S2rx0Pkk',
      })
        .then((res) => {
          if (res.length) {
            setModalData(
              () => res[0].fields as Entry<ContentfulModalAllLocales>['fields'],
            );
          }
        })
        .catch(() => {});
    }
  }, [hideGeoLocationModal, router.locale, userCountry]);

  useEffect(() => {
    if (modalData) {
      if (getCurrentPageName(router)) {
        findContentfulEntry({
          contentType: articleId
            ? CONTENT_TYPES.ARTICLE
            : CONTENT_TYPES.SECTION,
          contentfulId: articleId || sectionId,
          locale: userLocale,
        })
          .then((res) => {
            const typedRes = res as unknown as ContentfulArticle[];
            if (res.length) {
              setTargetRoute(
                createPathFromArray([
                  userLocale,
                  getCurrentPageName(router),
                  `${typedRes[0].sys.id}-${typedRes[0].fields.slug}`,
                ]),
              );
            }
          })
          .catch(() => {});
        return;
      }
      setTargetRoute(`/${userLocale}`);
    }
  }, [articleId, modalData, router, sectionId, userLocale]);

  if (!userCountry || hideGeoLocationModal) {
    return null;
  }

  if (
    userCountry &&
    userCountry !== getCountryFromLocale(router.locale) &&
    userLocale &&
    targetRoute &&
    modalData
  ) {
    setModal({
      onClose,
      closeButtonLabel: 'close',
      variant: 'contextual',
      children: (
        <ModalWrapper>
          <Headline as="h2" css={spacing({ bottom: 'giga' })} title="header">
            {
              (modalData.body[router.locale].content[0].content[0] as Text)
                .value
            }
          </Headline>
          <Button variant="primary" href={targetRoute} onClick={onClose}>
            {modalData.ctaPrimary[userLocale]}
          </Button>
          <StyledButton onClick={onClose}>
            {modalData.ctaSecondary[router.locale]}
          </StyledButton>
        </ModalWrapper>
      ),
    });
  }

  return <></>;
};

export default GeolocationModal;
