import React, {
  useState,
  MouseEventHandler,
  useEffect,
  useCallback,
  useRef,
  FC,
  useContext,
} from 'react';
import { useRouter } from 'next/router';
import { Avatar } from '@sumup/circuit-ui';
import { useAuth } from '@sumup/react-nanoauth';

import {
  DesktopMenu,
  MobileHamburger,
  NavigationContainer,
  AvatarContainer,
} from '../styled';

import NavItems from './components/NavItems';

import globalContext from 'components/GlobalContext';
import { STORAGE_KEYS } from 'constants/user';
import { MobileComponent } from 'components/styledComponents';
import { formatRedirectUrlFromRouter } from 'services/authClient';
import { isPreviewDeployment } from 'server/util/isPreviewDeployment';
import { setJsonToSession } from 'services/storage';

const HeaderNavigation: FC = () => {
  const auth = useAuth();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const router = useRouter();
  const navigationRef = useRef<HTMLDivElement>();
  const { authenticated, setAuthenticated } = useContext(globalContext);
  useEffect(() => {
    const handleOutsideClick: EventListener = (e) => {
      if (
        typeof navigationRef.current === 'object' &&
        !navigationRef?.current?.contains(e.target as HTMLElement)
      ) {
        setIsMenuOpened(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [authenticated]);

  const handleSignInClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      auth
        .authRedirect({
          redirectRoute: formatRedirectUrlFromRouter(router),
          ...(isPreviewDeployment()
            ? { sourceOrigin: window?.location?.origin }
            : {}),
        })
        .catch(() => {});
    },
    [auth, router],
  );

  const handleSignOutClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
      if (window?.embedded_svc) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
        window.embedded_svc.liveAgentAPI.clearSession();
      }
      setJsonToSession(STORAGE_KEYS.HAS_LOGGED_IN, false);
      setAuthenticated(false);
      auth
        .endOIDCSession({
          redirectRoute: formatRedirectUrlFromRouter(router),
          ...(isPreviewDeployment()
            ? { sourceOrigin: window?.location?.origin }
            : {}),
        })
        .catch(() => {});
    },
    [auth, router],
  );

  const handleMenuClick: MouseEventHandler = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <NavigationContainer ref={navigationRef}>
      {authenticated ? (
        <AvatarContainer onClick={handleMenuClick} isMenuOpened={isMenuOpened}>
          <Avatar variant="identity" alt="" />
        </AvatarContainer>
      ) : (
        <MobileComponent>
          <MobileHamburger
            size="kilo"
            isActive={isMenuOpened}
            onClick={handleMenuClick}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            activeLabel={`${isMenuOpened}`}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            inactiveLabel={`${!isMenuOpened}`}
          />
        </MobileComponent>
      )}
      <DesktopMenu>
        <NavItems
          authenticated={authenticated}
          handleSignInClick={handleSignInClick}
          handleSignOutClick={handleSignOutClick}
          isMenuOpened={isMenuOpened}
        />
      </DesktopMenu>
    </NavigationContainer>
  );
};
export default HeaderNavigation;
