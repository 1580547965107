import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  FC,
  useCallback,
  KeyboardEventHandler,
} from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { SearchInput } from '@sumup/circuit-ui';
import { useExperiment } from '@optimizely/react-sdk';
import { spacing } from '@sumup/circuit-ui/legacy';

import {
  SearchContainer,
  SearchSuggestionsContainer,
  SearchWrapper,
} from '../styled';

import { SearchResults } from 'types/search';
import { SearchProps } from 'types/components';
import AiSearchEntrypoint from 'components/AiSearchEntryPoint';
import SearchSuggestions from 'components/SearchSuggestions';
import { showSmartSearch } from 'services/common';
import { getSearchResults } from 'services/api/search';
import * as Analytics from 'services/analytics';
import { getCurrentPageName } from 'services/pages/common';
import * as ANALYTICS_CONSTANTS from 'constants/analytics';
import { KEY_CODES } from 'constants/events';
import {
  INITIAL_PAGE,
  EMPTY_SEARCH_RESULTS,
  MIN_LENGTH_OF_SEARCH_TERM,
  RESULTS_PER_PAGE_SEARCH_BAR,
} from 'constants/search';
import {
  SMART_SEARCH_EXPERIMENT_KEY,
  SMART_SEARCH_IT_EXPERIMENT_KEY,
} from 'constants/common';

const Component: FC<SearchProps> = ({
  inView,
  scrolledElement,
  centered,
  overlayEnabled,
  setOverlayEnabled,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] =
    useState<SearchResults>(EMPTY_SEARCH_RESULTS);
  const [trackingQuery, setTrackingQuery] = useState('');
  const [shouldDisplayAiEntryPoint, setShouldDisplayAiEntryPoint] =
    useState(false);
  const [shouldShowEntryPoint, setShouldShowEntryPoint] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [variation, clientReady] = useExperiment(
    router.locale === 'it-IT'
      ? SMART_SEARCH_IT_EXPERIMENT_KEY
      : SMART_SEARCH_EXPERIMENT_KEY,
    {
      autoUpdate: true,
    },
  );

  const enableOverlay = useCallback(() => {
    if (inView) {
      setOverlayEnabled(true);
      setShouldDisplayAiEntryPoint(true);
    }
  }, [inView, setOverlayEnabled]);
  const disableOverlay = useCallback(() => {
    if (inView) {
      setOverlayEnabled(false);
      setShouldDisplayAiEntryPoint(false);
    }
  }, [inView, setOverlayEnabled]);

  const clearSearch = useCallback(() => {
    searchRef?.current?.blur();
    setSearchValue('');
    setSearchResults(EMPTY_SEARCH_RESULTS);
    disableOverlay();
  }, [disableOverlay]);

  const sendSearchEvent = useCallback(() => {
    Analytics.AnalyticsEvents.sendSearchEvent({
      query: searchValue,
      event: ANALYTICS_CONSTANTS.EVENTS.INTERACTION,
      action: ANALYTICS_CONSTANTS.ACTIONS.SEARCH,
      pageName: getCurrentPageName(router),
      locale: router.locale,
    });
  }, [router, searchValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length >= MIN_LENGTH_OF_SEARCH_TERM) {
        sendSearchEvent();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, sendSearchEvent]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length >= MIN_LENGTH_OF_SEARCH_TERM) {
        if (searchValue !== trackingQuery) {
          Analytics.AnalyticsEvents.sendNewSearchEvent({
            query: searchValue,
            locale: router.locale,
            value: 'classic',
          });
        }
        setTrackingQuery(searchValue);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [router.locale, searchValue, sendSearchEvent, trackingQuery]);

  const handleSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(value);
  };

  const handleSearchBlur = () =>
    setTimeout(
      () => {
        if (searchValue !== trackingQuery) {
          Analytics.AnalyticsEvents.sendNewSearchEvent({
            query: searchValue,
            locale: router.locale,
            value: 'classic',
          });
        }
        setTrackingQuery('');
        if (!containerRef?.current?.contains(document?.activeElement)) {
          clearSearch();
        }
      },

      10,
    );

  useEffect(() => {
    clearSearch();
  }, [router.asPath, disableOverlay, clearSearch]);

  useEffect(() => {
    if (!overlayEnabled) {
      clearSearch();
    }
  }, [overlayEnabled, clearSearch]);

  useEffect(() => {
    if (!inView) {
      clearSearch();
    }
  }, [inView, clearSearch, setOverlayEnabled]);

  useEffect(() => {
    const handleOutsideClick: EventListenerOrEventListenerObject = (e) => {
      if (
        !inputRef?.current?.contains(e.target as HTMLElement) &&
        suggestionsRef?.current &&
        !suggestionsRef?.current?.contains(e.target as HTMLElement)
      ) {
        clearSearch();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [clearSearch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length >= MIN_LENGTH_OF_SEARCH_TERM) {
        getSearchResults({
          page: INITIAL_PAGE,
          size: RESULTS_PER_PAGE_SEARCH_BAR,
          locale: router.locale,
          searchTerm: searchValue,
        })
          .then((searchResponse) => setSearchResults(searchResponse.data))
          .catch(() => {});
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, router.locale]);

  useEffect(() => {
    if (showSmartSearch({ locale: router.locale, variation, clientReady })) {
      setShouldShowEntryPoint(true);
    } else {
      setShouldShowEntryPoint(false);
    }
  }, [clientReady, router.locale, variation]);

  const handleKeyDown: KeyboardEventHandler = (e) => {
    switch (e.which) {
      case KEY_CODES.ENTER:
        sendSearchEvent();
        clearSearch();
        if (searchValue.length) {
          router.push(`/search/${searchValue}`).catch(() => {});
        }
        break;
      case KEY_CODES.ESCAPE:
        clearSearch();
        break;
      default:
        break;
    }
  };

  return (
    <div ref={containerRef}>
      <SearchContainer
        onBlur={handleSearchBlur}
        ref={scrolledElement}
        centered={centered}
      >
        <SearchWrapper ref={inputRef}>
          <SearchInput
            css={spacing({ bottom: 'mega' })}
            ref={searchRef}
            value={searchValue}
            onFocus={enableOverlay}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            placeholder={t('header.search_placeholder')}
            label="{t('header.search_placeholder')}"
            hideLabel={true}
          />
        </SearchWrapper>
        {shouldShowEntryPoint && shouldDisplayAiEntryPoint ? (
          <SearchSuggestionsContainer fullWidth={false} onClick={clearSearch}>
            <AiSearchEntrypoint searchTerm={searchValue} />
          </SearchSuggestionsContainer>
        ) : (
          <>
            {searchResults?.articles?.length ? (
              <SearchSuggestionsContainer
                onClick={clearSearch}
                fullWidth={false}
                ref={suggestionsRef}
              >
                <SearchSuggestions
                  searchTerm={searchValue}
                  results={searchResults}
                />
              </SearchSuggestionsContainer>
            ) : null}
          </>
        )}
      </SearchContainer>
    </div>
  );
};

export default Component;
