import React, { FC, MouseEventHandler } from 'react';
import { Logout, Profile } from '@sumup/icons';
import { useTranslation } from 'next-i18next';

import {
  MenuItem,
  MenuButton,
  LoggedInMenu,
} from 'components/Header/components/styled';
import { DesktopComponent } from 'components/styledComponents';
import DashboardIcon from 'assets/icons/DashboardIcon';

type NavItemsProps = {
  authenticated;
  handleSignInClick: MouseEventHandler<Element>;
  handleSignOutClick: MouseEventHandler<Element>;
  isMenuOpened: boolean;
};

const NavItems: FC<NavItemsProps> = ({
  authenticated,
  handleSignInClick,
  handleSignOutClick,
  isMenuOpened,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!authenticated && (
        <DesktopComponent>
          <MenuItem>
            <MenuButton
              onClick={handleSignInClick}
              target="_blank"
              rel="noreferrer"
            >
              <Profile />
              {t('header.login')}
            </MenuButton>
          </MenuItem>
        </DesktopComponent>
      )}
      {isMenuOpened && (
        <LoggedInMenu isMenuOpened={isMenuOpened}>
          {!authenticated ? (
            <MenuItem>
              <MenuButton
                onClick={handleSignInClick}
                target="_blank"
                rel="noreferrer"
              >
                <Profile />
                {t('header.login')}
              </MenuButton>
            </MenuItem>
          ) : (
            <>
              <MenuItem>
                <MenuButton
                  href={t('header.dashboard.url')}
                  target="_blank"
                  rel="noreferrer"
                >
                  <DashboardIcon />
                  {t('header.dashboard.name')}
                </MenuButton>
              </MenuItem>
              <MenuItem>
                <MenuButton
                  onClick={handleSignOutClick}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Logout />
                  {t('header.logout')}
                </MenuButton>
              </MenuItem>
            </>
          )}
        </LoggedInMenu>
      )}
    </>
  );
};

export default NavItems;
