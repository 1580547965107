/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import '@sumup/design-tokens/light.css';
import '@sumup/circuit-ui/styles.css';
import { AppProps as NextAppProps } from 'next/app';
import { NextPage } from 'next';
import { isEmpty } from 'lodash/fp';
import { appWithTranslation } from 'next-i18next';
// eslint-disable-next-line @emotion/no-vanilla
import { Entry } from 'contentful';
import { ThemeProvider } from '@emotion/react';
import { ModalProvider } from '@sumup/circuit-ui';
import { elb } from '@elbwalker/walker.js';

import * as ANALYTICS_CONSTANTS from 'constants/analytics';
import { COOKIES } from 'constants/common';
import { DEFAULT_LOCALES } from 'constants/localization';
import { WithAuth } from 'components/Auth';
import globalContext from 'components/GlobalContext';
import AuthPersist from 'components/AuthPersist';
import { CookieConsentScript } from 'components/CookieConsent/public';
import GeolocationModal from 'components/GeolocationModal';
import Footer from 'components/Footer';
import { FooterData } from 'components/Footer/FooterTypes';
import Header from 'components/Header';
import { Meta } from 'components/Meta';
import OptimizelyEvents from 'components/OptimizelyEvents';
import PreviewButton from 'components/PreviewButton';
import TopNav from 'components/TopNav';
import { Overlay, HeaderSmallGrid } from 'components/styledComponents';
import { Col, Row } from 'components/TypedCircuit';
import { CustomLiveAgentProvider } from 'providers/CustomLiveAgent';
import Collector from 'providers/Collector/Collector';
import { LiveAgentProvider } from 'providers/LiveAgent';
import OptimizelyProvider from 'providers/Optimizely';
import { SearchResultsProvider } from 'providers/SearchResults';
import { ScrollProvider } from 'providers/SearchScroll';
import { UserProvider } from 'providers/User';
import * as Analytics from 'services/analytics';
import { GtmScript } from 'services/gtm';
import { getCountryDataForLocale } from 'services/localization';
import { getCurrentPageName } from 'services/pages/common';
import { setCookie } from 'services/storage';
import { ContentfulCountry, MetaData } from 'types/common';
import 'types/global';
// Solution to set the body background color to #fff,
// until Circuit UI theme update
import 'styles/document.css';
import GlobalStyles from 'src/components/GlobalStyles/GlobalStyles';
import { debugTheme } from 'src/components/GlobalStyles/debug-theme';

type AppProps<P> = {
  pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

type PageProps = {
  meta?: MetaData;
  articleId?: string;
  sectionId?: string;
  footerData?: FooterData;
  countriesData?: Entry<ContentfulCountry>[];
  preview?: null | true;
};

const App: NextPage<AppProps<PageProps>> = ({
  Component,
  pageProps,
  router,
}) => {
  const [overlayEnabled, setOverlayEnabled] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const overlayRef = useRef<HTMLDivElement>(null);
  const currentPage = getCurrentPageName(router);

  useEffect(() => {
    if (router.asPath) {
      elb('walker run');
      Analytics.AnalyticsEvents.sendEvent({
        'content-name': router.asPath,
        'event': ANALYTICS_CONSTANTS.EVENTS.CONTENT_VIEW,
        'pageType': Analytics.AnalyticsEvents.getPageType(currentPage),
        'locale': router.locale || DEFAULT_LOCALES.APP,
        ...(pageProps?.articleId && {
          article_id: pageProps.articleId,
        }),
      });
      Analytics.AnalyticsEvents.sendPageView({
        pageType: Analytics.AnalyticsEvents.getPageType(currentPage),
        locale: router.locale || DEFAULT_LOCALES.APP,
        ...(pageProps?.articleId && {
          article_id: pageProps.articleId,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (overlayEnabled) {
      overlayRef.current.addEventListener(
        'touchstart',
        (e) => e.preventDefault(),
        {
          passive: false,
        },
      );
    }
  }, [overlayEnabled]);

  useEffect(() => {
    setCookie({
      name: COOKIES.APP_LOCALE,
      value: router.locale,
      exdays: 1,
    });
  });

  // Temporary solution to remove the duplicate footer
  useEffect(() => {
    if (document) {
      const duplicateFooter = document.querySelector('body > footer');

      if (duplicateFooter) {
        duplicateFooter.remove();
      }
    }
  }, []);

  return (
    <globalContext.Provider value={{ authenticated, setAuthenticated }}>
      <WithAuth>
        <AuthPersist />
        <ThemeProvider theme={debugTheme}>
          <GlobalStyles />
          <UserProvider>
            <OptimizelyProvider>
              <ModalProvider>
                <CustomLiveAgentProvider>
                  <LiveAgentProvider
                    locale={router.locale}
                    shouldLoad={!isEmpty(pageProps)}
                    countryData={getCountryDataForLocale({
                      locale: router.locale,
                      countriesData: pageProps.countriesData,
                    })}
                    articleId={pageProps.articleId}
                  >
                    <Collector page={currentPage}>
                      <ScrollProvider>
                        <SearchResultsProvider>
                          <PreviewButton previewEnabled={pageProps.preview} />
                          <Meta {...pageProps.meta} />
                          {/* OneTrust cookie consent */}
                          {process.env.NEXT_PUBLIC_ONETRUST_ENABLED && (
                            <CookieConsentScript />
                          )}
                          <GtmScript />
                          <OptimizelyEvents />
                          <GeolocationModal
                            articleId={pageProps?.articleId}
                            sectionId={pageProps?.sectionId}
                          />
                          {overlayEnabled ? (
                            <Overlay
                              ref={overlayRef}
                              onClick={() => setOverlayEnabled(false)}
                              onTouchEnd={() => setOverlayEnabled(false)}
                              enabled={overlayEnabled}
                            />
                          ) : null}
                          <TopNav
                            overlayEnabled={overlayEnabled}
                            setOverlayEnabled={setOverlayEnabled}
                            countriesData={pageProps.countriesData}
                          />
                          <HeaderSmallGrid>
                            {currentPage === 'ai-search' ? (
                              <Component {...pageProps} key={router.asPath} />
                            ) : (
                              <>
                                <Header
                                  overlayEnabled={overlayEnabled}
                                  setOverlayEnabled={setOverlayEnabled}
                                />
                                <Row>
                                  <Col span="12" skip="0">
                                    <Component
                                      {...pageProps}
                                      key={router.asPath}
                                    />
                                  </Col>
                                </Row>
                              </>
                            )}
                          </HeaderSmallGrid>
                          <Footer
                            locale={router.locale}
                            footerData={pageProps.footerData}
                            countriesData={pageProps.countriesData}
                          />
                        </SearchResultsProvider>
                      </ScrollProvider>
                    </Collector>
                  </LiveAgentProvider>
                </CustomLiveAgentProvider>
              </ModalProvider>
            </OptimizelyProvider>
          </UserProvider>
        </ThemeProvider>
      </WithAuth>
    </globalContext.Provider>
  );
};

export default appWithTranslation(App);
