import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Anchor, Card, Headline, Image, Body, Button } from '@sumup/circuit-ui';
import { Grid, Col } from '@sumup/circuit-ui/legacy';
import { Close } from '@sumup/icons';
import { forwardRef } from 'react';

import { StyledComponentsParams } from 'types/components';
import { Children } from 'types/common';

export const ArticleTitle = styled(Headline)(
  ({ theme }: StyledComponentsParams) => css`
    margin-bottom: ${theme.spacings.mega};
  `,
);

// eslint-disable-next-line prefer-arrow-callback
export const Hyperlink = forwardRef(function CustomAnchor(
  props: {
    size?: any;
    children: boolean | Children | string;
    href?: string;
    target?: string;
  },
  /* eslint-disable @typescript-eslint/no-unused-vars */
  _ref,
) {
  const StyledAnchor = styled(Anchor)(
    ({ theme }: StyledComponentsParams) => css`
      text-decoration: underline;
      color: var(--cui-bg-strong);
      display: inline;

      &:hover,
      &:visited:hover {
        color: var(--cui-bg-accent-strong);
      }

      &:visited,
      &:visited:active,
      &:active,
      &:focus {
        color: var(--cui-bg-strong);
      }
    `,
  );
  return <StyledAnchor {...props}>{props.children}</StyledAnchor>;
});

export const Overlay = styled('div')(
  ({ theme, enabled }: StyledComponentsParams & { enabled: boolean }) => css`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: var(--cui-bg-normal);
    z-index: ${theme.zIndex.popover};
    opacity: 0.9;
    display: ${enabled ? 'block' : 'none'};
  `,
);

export const SectionCard = styled(Card)`
  width: 100%;
  height: 100%;
  padding: 0;
  justify-content: flex-start;
  position: relative & > * {
    text-align: left;
    clear: both;
    float: left;
  }
  div:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }
`;

export const SectionCardContent = styled(Card)(
  ({ theme }: StyledComponentsParams) => css`
    border: none;
    box-shadow: none;
    padding: ${theme.spacings.giga};

    p {
      margin-bottom: ${theme.spacings.byte};
    }

    p:last-child {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: ${theme.spacings.mega};
    }
  `,
);

export const SectionCardNavigation = styled(Card)(
  ({ theme }: StyledComponentsParams) => css`
    border: none;
    box-shadow: none;
    border-top: 2px solid var(--cui-bg-normal-pressed);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: ${theme.spacings.mega} ${theme.spacings.giga};

    a {
      svg {
        margin-left: ${theme.spacings.byte};
        transform: translateX(0);
        transition: all 0.18s ease-in-out;
      }
    }

    a {
      :hover {
        svg {
          transform: translateX(5px);
        }
      }
    }
  `,
);

export const SectionContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    box-sizing: border-box;
    margin-right: ${theme.spacings.giga};
    margin-bottom: ${theme.spacings.giga};

    ${theme.mq.untilMega} {
      width: 100%;
      margin-right: 0;
      margin-bottom: ${theme.spacings.mega};
    }
    ${theme.mq.megaToGiga} {
      width: calc(50% - ${theme.spacings.giga});
      margin-bottom: ${theme.spacings.giga};
    }
    ${theme.mq.giga} {
      width: calc(33.3% - ${theme.spacings.giga});
      margin-bottom: ${theme.spacings.giga};
    }
  `,
);

export const SectionsListContainer = styled(Col)(
  ({ theme }: StyledComponentsParams & { children?: Children }) => css`
    margin-top: ${theme.spacings.peta};

    ${theme.mq.mega} {
      padding-left: 0;
    }

    p:last-child {
      margin-bottom: ${theme.spacings.tera};
    }
  `,
);

export const SectionsListItemContainer = styled(Body)(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacings.kilo};
  `,
);

export const SectionsListItem = styled(Anchor)(
  ({ selected }: StyledComponentsParams & { selected: boolean }) => css`
    text-decoration: none;
    color: ${selected ? 'var(--cui-fg-accent)' : 'var(--cui-fg-normal)'};

    &:hover,
    &:visited:hover {
      color: ${selected ? 'var(--cui-fg-accent)' : 'var(--cui-fg-normal)'};
    }
    &:visited,
    &:visited:active,
    &:active,
    &:focus {
      color: ${selected ? 'var(--cui-fg-accent)' : 'var(--cui-fg-normal)'};
    }
  `,
);

export const SectionsListTitle = styled(Body)(
  ({ theme }: StyledComponentsParams) => css`
    margin-bottom: ${theme.spacings.mega};
    display: block;
  `,
);

export const StyledSectionIcon = styled(Image)(
  ({ theme }: StyledComponentsParams) => css`
    width: ${theme.spacings.peta};
    height: ${theme.spacings.peta};
    margin-right: ${theme.spacings.mega};
  `,
);

export const StyledIcon = styled(Image)(
  ({ theme }: StyledComponentsParams) => css`
    width: calc(${theme.iconSizes.giga} + ${theme.iconSizes.mega});
    height: calc(${theme.iconSizes.giga} + ${theme.iconSizes.mega});
    margin-bottom: ${theme.spacings.mega};
  `,
);

export const TypeContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacings.tera};
    ${theme.mq.untilMega} {
      width: 100%;
    }
    ${theme.mq.mega} {
      width: calc(100% + ${theme.spacings.giga});
    }
  `,
);

export const TopicTitle = styled(Headline)(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.mq.untilMega} {
      margin-bottom: ${theme.spacings.mega};
    }
    ${theme.mq.mega} {
      margin-bottom: ${theme.spacings.giga};
    }
  `,
);

export const MobileSectionList = styled('div')(
  ({
    theme,
    mobileMenuVisibility,
  }: StyledComponentsParams & { mobileMenuVisibility: boolean }) => css`
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    margin: 0;
    transition: all 0.3s ease-in-out;
    overflow-y: scroll;
    max-height: 90vh;
    border-top-left-radius: calc(${theme.borderRadius.mega} * 4);
    border-top-right-radius: calc(${theme.borderRadius.mega} * 4);
    background-color: var(--cui-bg-normal);
    padding: ${theme.spacings.giga};
    transform: translateY(${mobileMenuVisibility && '100%'});
  `,
);

export const CloseIcon = styled(Close)(
  ({ theme }: StyledComponentsParams) => css`
    color: var(--cui-bg-accent-strong);
  `,
);

export const MobileListContainer = styled('div')(
  ({
    theme,
    mobileMenuVisibility,
  }: StyledComponentsParams & { mobileMenuVisibility: boolean }) => css`
    position: fixed;
    background-color: transparent;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    z-index: ${theme.zIndex.popover};
    visibility: ${mobileMenuVisibility ? 'hidden' : 'visible'};
  `,
);

export const MobileTrigger = styled(Button)(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${theme.borderRadius.byte};
    margin-bottom: ${theme.spacings.peta};

    span:last-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  `,
);

export const DesktopComponent = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.mq.untilMega} {
      display: none;
    }
    ${theme.mq.megaToGiga} {
      display: none;
    }
    ${theme.mq.giga} {
      display: block;
    }

    div:first-child {
      ${theme.mq.untilMega} {
        margin-top: ${theme.spacings.peta};
      }
      ${theme.mq.mega} {
        margin-top: 0;
      }
    }
  `,
);

export const MobileComponent = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.mq.untilMega} {
      display: block;
      z-index: ${theme.zIndex.popover};
    }
    ${theme.mq.megaToGiga} {
      display: block;
      z-index: ${theme.zIndex.popover};
    }
    ${theme.mq.giga} {
      display: none;
    }
  `,
);

export const MobileCountryPicker = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.mq.untilMega} {
      display: flex;
      align-items: center;
      z-index: ${theme.zIndex.popover};
    }
    ${theme.mq.megaToGiga} {
      display: flex;
      align-items: center;
      z-index: ${theme.zIndex.popover};
    }
    ${theme.mq.giga} {
      display: none;
    }
  `,
);

// Replace with standard Circuit-UI Col when it is typescript compliant
export const PageContent = styled(Col)(
  ({ theme }: StyledComponentsParams & { children?: Children }) => css`
    width: 100%;

    ${theme.mq.giga} {
      width: 66.6%;
      margin-bottom: ${theme.spacings.zetta};
    }
  `,
);

export const PageContentTitle = styled(Headline)(
  ({ theme }: StyledComponentsParams) => css`
    margin-bottom: ${theme.spacings.giga};
  `,
);

// Replace with standard Circuit-UI Col when it is typescript compliant
export const PageNavigation = styled(Col)(
  ({ theme }: StyledComponentsParams & { children?: Children }) => css`
    width: 100%;

    ${theme.mq.giga} {
      width: 33.3%;
      position: sticky;
      top: ${theme.spacings.giga};
      max-height: 90vh;
      overflow-y: scroll;
      margin-bottom: ${theme.spacings.giga};
    }
  `,
);

export const SmallGrid = styled(Grid)(
  ({ theme }: StyledComponentsParams & { children?: Children }) => css`
    @media only screen and (max-width: 767px) {
      padding: 0 ${theme.spacings.mega};
    }
    @media only screen and (min-width: 1280px) {
      max-width: 1000px;
    }
  `,
);

export const HeaderSmallGrid = styled(Grid)(
  ({ theme }: StyledComponentsParams & { children?: Children }) => css`
    position: relative;
    top: calc(${theme.spacings.zetta} + ${theme.spacings.kilo});
    @media only screen and (max-width: 767px) {
      padding: 0 ${theme.spacings.mega};
    }
    @media only screen and (min-width: 1280px) {
      max-width: 1000px;
    }
  `,
);

export const ViewArticle = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--cui-bg-accent-strong);
    &:visited,
    &:visited:active,
    &:visited:hover,
    &:hover,
    &:active,
    &:focus {
      color: var(--cui-bg-accent-strong);
    }
  `,
);

export const PreviewEditButton = styled(Button)(
  ({ theme }: StyledComponentsParams) => css`
    position: absolute;
    right: calc((-1) * ${theme.spacings.zetta});
  `,
);

export const PreviewToggleButton = styled(Button)(
  ({ theme }: StyledComponentsParams) => css`
    position: fixed;
    right: ${theme.spacings.zetta};
    bottom: ${theme.spacings.giga};
  `,
);
